.variant-list {
  flex-wrap: nowrap !important;
  overflow-y: overlay;
  overflow-x: hidden;
  height: 230px;
  resize: vertical;
}

.variant-list .nav-item .nav-link {
  white-space: nowrap;
}

/* width */
.variant-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.variant-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.variant-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.variant-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

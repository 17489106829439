.action-bar {
  flex-wrap: wrap;
  white-space: nowrap;
  row-gap: 6px;
}



@media screen and (max-width: 1293px) {
  .action-bar {
 
     padding-top: 29px; 
   
  }
  
}

.profile-user-img {
  width: 125px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: auto;
  margin-bottom: 20px;
  margin-right: 20px;

  .avatar-edit {
    position: absolute;
    right: 8px;
    z-index: 1;
    top: 90px;
    .btt {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        text-align: center;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid #d2d6de;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
        &:after {
          color: #337ab7;
          position: absolute;
          left: 0;
          right: 0;
          text-align: center;
          line-height: 34px;
          margin: auto;
        }
      }
    }
  }
}

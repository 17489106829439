@import 'react-image-crop/dist/ReactCrop.css';
@import 'react-toastify/dist/ReactToastify.css';

@import 'react-data-grid/lib/styles.css';

body {
  margin: 0;
  font-family:  'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  font-style: normal;
  font-weight: auto;
  src: url('../../fonts/helvetica-neue-light.ttf');
}

@font-face {
  font-family: 'Helvetica Neue Ext';
  font-style: normal;
  font-weight: auto;
  src: url('../../fonts/helvetica-neue-ext.ttf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  font-style: normal;
  font-weight: auto;
  src: url('../../fonts/helvetica-neue-medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  font-style: normal;
  font-weight: auto;
  src: url('../../fonts/helvetica-neue-bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue Black';
  font-style: normal;
  font-weight: auto;
  src: url('../../fonts/helvetica-neue-black.ttf') format('opentype');
}

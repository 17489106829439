.simple-bar-gallery-container {
  max-height: 200px;
}

.simple-gallery-wrapper {
  overflow-x: scroll;
  white-space: nowrap;
}

.very-pale-green {
  // not sure if this is really visible, but leaving it as is for now
  background-color: #c9f2d6 !important;
  color: black;
}

/* Example CSS to change the background color of buttons in FullCalendar */
.fc-button {
  background-color: #e9e9ef !important;
  border-color: #e9e9ef !important;
  color: #000 !important;
  margin-right: 0.5;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  line-height: var(--bs-btn-line-height);
  text-transform: none;
  appearance: button;
  cursor: pointer;
  display: inline-block;
  font-weight: var(--bs-btn-font-weight);
  color: var(--bs-btn-hover-color);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: var(--bs-btn-hover-bg);
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-color: currentcolor;
  text-decoration-thickness: auto;
  box-shadow: rgba(233, 233, 239, 0.5) 0px 2px 6px 0px;
}

/* Additional styling for hover or active state if needed */
.fc-button:hover {
  background-color: #c6c6c6 !important;
  border-color: #c6c6c6 !important;
}

.fc-button-active {
  color: #fff !important;
  background-color: #bababf !important;
  border-color: #bababf !important;
}
.fc-button:disabled {
  color: #000;
  pointer-events: none;
  background-color: #e9e9ef;
  border-color: #e9e9ef !important;
  opacity: var(--bs-btn-disabled-opacity);
}

.fc-daygrid-dot-event {
  padding: 0;
  div {
    // background-color: rgb(255, 0, 0) !important;
    flex-grow: 1;
    border-radius: 2px;
  }
}

@media print {

  @page {
    size: 28.3cm 35.7cm;
  }
  
  div {
    color: #000;
  }
  .calendar-no-print {
    display: none;
  }
}




.form-file-upload {
  max-width: 100%;
  text-align: center;
  position: relative;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  align-items: center;
  border-color: #cbd5e1;
  border-radius: 5px;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  min-height: 38px;
  padding: 5px 0;
}

.label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  font-size: 1em;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: 'Oswald', sans-serif;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.rdg-no-reboot {
  label {
    margin-bottom: 0 !important;
    display: flex !important;
  }
  min-height: 800px; /* Set your desired minimum height here */
}

.WQUTE {
  padding: 0 0 0 0 !important;
}
.cellStyle {
  background-color: #e0e0e088 !important;
}

.border-start--highlighted {
  border-left-width: 2px;
  border-left-color: #c9c9cd;
}
.border-start--1 {
  border-left-width: 1px;
  border-left-color: #c9c9cd;
}

.scroll-modal-pricing {
  /* Set the height and width of the container */
  height: 25vh;
  width: 100%;
  /* Allow vertical scrolling */
  overflow-y: scroll;
  /* Hide horizontal scrollbar */
  overflow-x: hidden;
  /* Create space for the scrollbar without affecting the layout */
  padding-right: 10px;
  margin-right: -10px;

  /* Standard scrollbar styles */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the background color of the scrollbar track */
  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-thumb {
    background: #888; /* Set the background color of the scrollbar thumb */
  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the background color of the scrollbar thumb when hovered */
  }
}

/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-700;
}

[data-bs-theme='dark'] {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--#{$prefix}gray-400);
  }
}

a {
  text-decoration: none !important;
}

label {
  margin-bottom: $form-label-margin-bottom;
  font-weight: $font-weight-medium;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid var(--#{$prefix}secondary-bg);
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid var(--#{$prefix}secondary-bg);
  text-align: right;
}

// container

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

// row

.row {
  > * {
    position: relative;
  }
}

.result-item {
  &:hover {
    background-color: var(--bs-gray-100);
    cursor: pointer;
    color: var(--bs-primary-700);
  }
}

.scroll-modal {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  margin-right: -10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.tab-pane {
  flex-wrap: nowrap !important;
  overflow-x: overlay;
  overflow-y: hidden;
}

.tab-pane .nav-item .nav-link {
  white-space: nowrap;
}

/* width */
.tab-pane::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
.tab-pane::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
.tab-pane::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.tab-pane::-webkit-scrollbar-thumb:hover {
  background: #555;
}

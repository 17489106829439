.scroll-table {
  /* Set the height and width of the container */
  /* Allow vertical scrolling */
  /* Hide horizontal scrollbar */
  overflow-x: scroll;
  /* Create space for the scrollbar without affecting the layout */


  /* Standard scrollbar styles */
  scrollbar-width: auto; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox */

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar {
    width: 7px; /* Set the width of the scrollbar */
    height: 7px; /* Set the height of the scrollbar */
  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-track {
    background: rgb(244, 242, 242); /* Set the background color of the scrollbar track */
  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-thumb {
    background: #9a9999; /* Set the background color of the scrollbar thumb */
    border-radius: 5px;

  }

  /* Webkit-based browsers (Safari and some versions of Chrome) */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the background color of the scrollbar thumb when hovered */
  }
}


.highlighter-level--1 {
  background-color: #ff9999;
}

.highlighter-level--2 {
  background-color: #ffcccc;
}

.highlighter-level--3 {
  background-color: #ffe5e5;
}

.highlighter-level--4 {
  background-color: #fff4f4;
}

.stock-text--success {
  color: #3a9d23 !important;
}

.stock-text--info {
  color: #4169e1 !important;
}

.cursor-state--idle {
  cursor: pointer;
}
.cursor-state--loading {
  cursor: wait;
}
